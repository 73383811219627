header {
  padding: 30px 35px;
}

header .navbar-collapse {
  justify-content: center;
}

header .navbar-brand {
  background: linear-gradient(103.84deg, #df1cff -25.59%, #ea0070 138%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 700;
  font-size: 28px;
}

header .navbar_link {
  font-family: "Akaya Kanadaka";
  font-size: 22px;
  font-weight: 400;
  line-height: 26.07px;
  color: rgba(255, 255, 255, 0.5);
  margin-right: 30px;
}

header .active {
  color: #fff;
}
