.form_box,
.privacy_box {
  padding: 50px 30px;
  text-align: center;
}
.privacy_box {
  padding: 50px 30px;
  text-align: center;
}
.participate .form_box form {
  padding: 0 200px;
  margin-top: 30px;
}

.participate .form_box form input {
  margin-bottom: 30px;
}
