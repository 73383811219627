.login {
  padding: 90px 0;
  position: relative;
  height: 100vh;
}

.login .login_img img {
  width: 100%;
}

.login_content,
.otp_content {
  background: rgba(255, 255, 255, 0.1);
  padding: 50px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.login_content .react-tel-input {
  max-width: fit-content;
  padding: 0 !important;
  margin-bottom: 20px;
}

.login_content .react-tel-input .flag-dropdown {
  border: none !important;
}

.login_content .react-tel-input .form-control {
  background: rgba(3, 3, 33, 1);
  color: rgba(255, 255, 255, 1);
  font-family: "Almarai";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  border: none;
  line-height: 130%;
  border-radius: 16px;
  letter-spacing: 2px;
}

.login_content .react-tel-input .flag-dropdown.open {
  background: rgba(3, 3, 33, 1);
  border-radius: 16px;
}

.login_content .react-tel-input .flag-dropdown,
.login_content .react-tel-input .flag-dropdown.open .selected-flag {
  background-color: unset;
  border-radius: unset;
}

.login_content .react-tel-input .selected-flag:hover,
.react-tel-input .selected-flag:focus {
  background-color: unset;
}

.login_content .country-list li {
  color: #000;
}

.otp_content {
  padding: 50px 50px 20px 50px;
}

.otp_content input {
  border-radius: 4px;
  border: none;
  background: rgba(3, 3, 33, 1);
  color: rgba(255, 255, 255, 1);

  min-width: 40px;
  height: 40px;
  margin: 10px 7px;
}
