.winner_box {
  padding: 50px 30px;
}

.winners .checking_vote_text {
  text-align: center;
  font-family: "Almarai";
  font-size: 24px;
  font-weight: 400;
  line-height: 36px;
  margin-top: 40px;
}

.winners .checking_vote_text a {
  font-weight: 700;
  color: rgba(233, 87, 192, 1);
  /* border-bottom: 1px solid rgba(233, 87, 192, 1); */
  position: relative;
  cursor: pointer;
}

.winners .checking_vote_text a::after {
  position: absolute;
  content: "";
  width: 100%;
  height: 2px;
  background: rgba(233, 87, 192, 1);
  left: 0;
  bottom: 5px;
}
