.congretulation_box {
  padding: 50px 30px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.congretulation_box .img_box {
  width: 300px;
}

.congretulation_box .underLine::after {
  display: none;
}
