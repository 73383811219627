.hero-img img {
  position: relative;
  right: 40px;
}
.hero-sections .content h1 {
  font-family: "Akaya Kanadaka";
  font-size: 52px;
}
.hero-sections .content h4 {
  font-family: "Akaya Kanadaka";
  font-size: 34px;
}
.hero-sections .content a {
  display: block;
  margin-top: 46px;
  max-width: fit-content;
}

.hero-sections .content ul li {
  list-style: none;
  font-family: "Almarai";
}

.hero-sections .content ul {
  display: flex;
  justify-content: space-between;
  max-width: 80%;
  margin-top: 20px;
}

.hero-sections .content ul li span {
  font-size: 20px;
  font-weight: 400;
}

.hero-sections .content ul li h4 {
  font-family: "Almarai";
  font-size: 36px;
  font-weight: 800;
  line-height: 55px;
}

.hero-img img {
  max-width: 510px;
  position: absolute;
  right: 0;
  top: 140px;
}

section.hero-sections {
  position: relative;
  padding: 90px 0;
}

/* contest */

.contest .contest_box .context_inner {
  background: url(../../assets/image/border.png);
  width: 100%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  position: relative;
}

.contest .contest_box .context_inner .over_img {
  position: absolute;
  top: -32px;
  right: 12px;
  width: 72px;
  height: 72px;
}

.contest .contest_box .context_inner .over_img img {
  width: 100%;
}

.contest .content {
  width: 80%;
  padding: 30px 15px 0px;
}

.contest .content h6 {
  font-family: "Akaya Kanadaka";
  font-size: 28px;
  font-weight: 400;
  line-height: 33.18px;
}

.contest .contest_box p {
  padding: 15px 15px;
}

/*----------camera--------------*/

.camera {
  margin: 100px 0;
}

.camera .content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

/*--------week winner-----------*/

.week_winner,
.subscribe {
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(0, 0, 0, 1);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 50px 0;
}

.week_winner .img_box {
  width: 400px;
  margin: 0 auto;
}
.week_winner .img_box h6 {
  background: linear-gradient(107.01deg, #e957c0 38.29%, #5091f3 94.72%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 60px;
  padding: 30px 0;
}

.week_winner_img {
  position: relative;
  margin-top: 120px;
}

.week_winner_img .king_img {
  position: absolute;
  top: -96px;
  left: 50%;
  transform: translate(-50%, 0px);
  z-index: -1;
}

/*-----------winners_gallery***********************/
.winners_gallery {
  padding-top: 100px;
  padding-bottom: 50px;
  position: relative;
}

.winners_gallery .winners_gallery_inner img {
  width: 100%;
}

.winners_gallery::before {
  content: url(../../assets//image/line.png);
  top: 260px;
  left: 0;
  position: absolute;
}

/* -------------subscribe------------*/

.subscribe .subscribe_content {
  width: 70%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}

/*---------------state-------------*/

.state {
  padding: 50px 0;
}

.state_content ul {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.state_content ul li {
  list-style-type: none;
  padding-left: 20px;
  padding-bottom: 20px;
  font-family: Almarai;
  font-size: 23px;
  font-weight: 700;
  line-height: 43.7px;
}
