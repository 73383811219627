* {
  margin: 0;
  margin-bottom: 0;
  padding: 0;
  box-sizing: border-box;
}
html {
  scroll-behavior: smooth;
}
body {
  overflow-x: hidden;
  font-size: 16px;
  color: rgba(255, 255, 255, 0.9);
  background: rgba(4, 1, 28, 1);

  /* line-height: 30px; */
  position: relative;
}

body:after {
  background: url(../image/overlay.png);
  position: absolute;
  left: 0;
  top: -170px;
  width: 100%;
  height: 1040px;
  content: "";
  background-size: 100% 100%;
  background-position: bottom;
  background-repeat: no-repeat;
  z-index: -1;
}

/* body::after {
  content: url(../image/overlay.png);
  position: absolute;
} */

a {
  text-decoration: none;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
}

input:focus {
  outline: none !important;
  box-shadow: none !important;
}

.mb_30 {
  margin-bottom: 30px;
}

.mb_50 {
  margin-bottom: 50px;
}

.mt_50 {
  margin-top: 50px;
}

.box_bg {
  background: rgba(255, 255, 255, 0.1);
}

/*------heading-------*/
.sub_heading {
  font-family: "Akaya Kanadaka";
  font-size: 40px;
  font-weight: 400;
  line-height: 49.57px;
}

.main_heading {
  font-family: "Akaya Kanadaka";
  font-size: 48px;
  font-weight: 400;
  line-height: 56.88px;
}

.image_heading {
  display: flex;
  align-items: center;
  justify-content: center;
}

/*-------paragraph--------*/
.main_text {
  font-family: "Almarai";
  font-size: 25px;
  font-weight: 400;
  line-height: 37.5px;
}

.main_text {
  font-family: "Almarai";
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
}

.underLine {
  font-weight: 700;
  color: rgba(233, 87, 192, 1);
  /* border-bottom: 1px solid rgba(233, 87, 192, 1); */
  position: relative;
  cursor: pointer;
}

.underLine::after {
  position: absolute;
  content: "";
  width: 100%;
  height: 2px;
  background: rgba(233, 87, 192, 1);
  left: 0;
  bottom: 5px;
}

/*--------button----------------------*/

.btn_linear {
  font-family: "Amaranth";
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: 0.02em;
  box-shadow: 0px 16px 80px 0px rgba(208, 17, 204, 0.15);
  color: rgba(255, 255, 255, 1);
  border: none;
  border-radius: 8px;
  padding: 12px 40px 12px 40px;
  background: linear-gradient(105.48deg, #de36bb 4.13%, #5091f3 92.43%),
    linear-gradient(0deg, rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05));
}

.btn_boder {
  font-family: "Amaranth";
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: 0.02em;
  box-shadow: 0px 16px 80px 0px rgba(208, 17, 204, 0.15);
  color: rgba(255, 255, 255, 1);
  border: 2px solid;
  border-image: linear-gradient(
    98.12deg,
    #ec2db6 -4.28%,
    rgba(56, 160, 255, 0.87) 118.96%
  );

  border-image-slice: 1; /* Ensures the gradient covers the entire border */
  /* border-radius: 15px; */
  border-radius: 4px;
  background: linear-gradient(105.48deg, #de36bb 4.13%, #5091f3 92.43%),
    linear-gradient(0deg, rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  padding: 12px 40px 12px 40px;
}

.upload input {
  display: none;
}

/*-----img_box------------*/

.img_box {
  background: rgba(255, 255, 255, 0.9);
  border: 1.35px solid;
  padding: 15px;
  border-image: linear-gradient(180deg, #e957c0 0%, #5091f3 100%) 1;
}

.img_box img {
  width: 100%;
}

.img_box h6 {
  font-family: "Akaya Kanadaka";
  font-size: 24px;
  font-weight: 400;
  line-height: 28.44px;
  color: rgba(68, 26, 56, 1);
  text-align: center;
  padding-top: 15px;
}

.img_box p {
  font-family: "Almarai";
  font-size: 15px;
  font-weight: 400;
  line-height: 22.5px;
  color: rgba(68, 26, 56, 0.6);
  text-align: center;
}

/*---------input***-----------*/

.input-group {
  flex-wrap: unset;
}

.custom_input {
  color: rgba(255, 255, 255, 1);
  font-family: "Almarai";
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  background: rgba(3, 3, 33, 1);
  padding: 10px 24px;
  width: 100%;
  border: none;
  border-radius: 16px;
}

.custom_input::placeholder,
.form-select {
  color: rgba(255, 255, 255, 0.56);
}

.search_input {
  position: relative;
}
.search_input input {
  padding-left: 50px;
}

.search_input i {
  position: absolute;
  color: rgba(255, 255, 255, 0.56);
  top: 16px;
  left: 20px;
  /* background: url(../image/download.svg);   */
}

.select_box select {
  border-radius: 16px;
  background: rgba(3, 3, 33, 1);
  border: none;
  color: rgba(255, 255, 255, 1);
  font-family: "Almarai";
  font-size: 20px;
  --bs-form-select-bg-img: url(../image/download.svg);
}
header + div {
  min-height: calc(100vh - 230px);
}
