.allParticipants .participants_list {
  padding: 20px 40px;
  height: 500px;
  overflow-y: auto;
}

.allParticipants .total_participants {
  padding: 20px 20px;
  height: 500px;
  overflow-y: auto;
}

.allParticipants .total_participants h6 {
  font-family: "Almarai";
  font-size: 28px;
  font-weight: 700;
  line-height: 42px;
  margin-top: 15px;
  margin-bottom: 15px;
}

.allParticipants .total_participants p {
  font-family: "Almarai";
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
  padding-bottom: 10px;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(233, 87, 192);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(233, 87, 192);
}
