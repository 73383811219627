.vote_box,
.participate_box {
  padding: 50px 200px;
  text-align: center;
}

.vote_checking .result .img_box + p {
  font-family: "Almarai";
  font-size: 31.86px;
  font-weight: 800;
  line-height: 47.78px;
  margin-bottom: 20px;
}

.vote_checking .result .img_box {
  width: 300px;
  margin: 0 auto;
}
