@font-face {
  font-family: "Akaya Kanadaka";
  src: url("../../font/AkayaKanadaka-Regular.woff2") format("woff2"),
    url("../../font/AkayaKanadaka-Regular.woff") format("woff"),
    url("../../font/AkayaKanadaka-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Almarai";
  src: url("../../font/Almarai-ExtraBold.woff2") format("woff2"),
    url("../../font/Almarai-ExtraBold.woff") format("woff"),
    url("../../font/Almarai-ExtraBold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Almarai";
  src: url("../../font/Almarai-Light.woff2") format("woff2"),
    url("../../font/Almarai-Light.woff") format("woff"),
    url("../../font/Almarai-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Almarai";
  src: url("../../font/Almarai-Regular.woff2") format("woff2"),
    url("../../font/Almarai-Regular.woff") format("woff"),
    url("../../font/Almarai-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Almarai";
  src: url("../../font/Almarai-Bold.woff2") format("woff2"),
    url("../../font/Almarai-Bold.woff") format("woff"),
    url("../../font/Almarai-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Amaranth";
  src: url("../../font/Amaranth-BoldItalic.woff2") format("woff2"),
    url("../../font/Amaranth-BoldItalic.woff") format("woff");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Amaranth";
  src: url("../../font/Amaranth-Bold.woff2") format("woff2"),
    url("../../font/Amaranth-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Amaranth";
  src: url("../../font/Amaranth-Italic.woff2") format("woff2"),
    url("../../font/Amaranth-Italic.woff") format("woff");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Amaranth";
  src: url("../../font/Amaranth-Regular.woff2") format("woff2"),
    url("../../font/Amaranth-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
